<template><div></div></template>

<script>
export default {
  mounted() {
    let date = new Date()

    this.$router.push({
      name: 'commission-overview.summary',
      params: {
        month: ('0' + (date.getMonth() + 1)).slice(-2),
        year: date.getFullYear(),
      },
    })
  },
}
</script>

<style></style>
